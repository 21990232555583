function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
var COOKIE_NAME = 'ilc-i18n';
function getCookieOpts() {
  return {
    path: '/',
    expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
  };
}
function encode(val) {
  if (_typeof(val) !== 'object' || !val.locale || !val.currency) {
    throw new Error("Invalid format of the passed i18n data for encoding. Passed data: ".concat(JSON.stringify(val)));
  }
  return "".concat(val.locale, ":").concat(val.currency);
}
function decode(val) {
  var vals = val ? val.split(':') : [];
  if (vals.length < 2) {
    return {};
  }
  return {
    locale: vals[0],
    currency: vals[1]
  };
}
module.exports = {
  getOpts: getCookieOpts,
  name: COOKIE_NAME,
  encode: encode,
  decode: decode
};